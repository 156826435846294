<template>
    <div />
</template>

<script>
export default {
    name: 'ProjectSingle'
}
</script>

<style scoped>

</style>
